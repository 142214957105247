<template>
  <transition name="slide-fade-dropdown">
    <div v-if="open" class="c-header-profile-wrap">
      <div class="c-header-burn-conf popup-wrap">
        <p class="c-header-ticket-conf-title">
          {{ title }}
        </p>
        <p class="c-header-ticket-conf-text"
           v-html="formattedText"
        ></p>
        <div class="c-header-ticket-conf-btn" @click="closeErrorPopup">
          <img :src="require('../../assets/svg-icons/icon_confirm.svg')"
            style="fill: none"
           alt=""/>
          OK
        </div>
        <div @click="closeErrorPopup">
          <img :src="require('../../assets/svg-icons/icon_close.svg')"
            class="c-header-profile-close"
           alt=""/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: "ErrorPopup",
  components:{
  },
  props: {
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: "Warning",
    },
    text: {
      type: String,
      required: true,
      default: "",
    },
  },
  data(){
    return{
      isOpen:this.open,
    }
  },
  computed: {
    formattedText() {
      const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
      return this.text.replace(urlRegex, function(url) {
        return `<a href="${url}" target="_blank">link</a>`;
      });
    }
  },
  methods:{
    closeErrorPopup(){
      this.$emit("closeErrorPopup")
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-wrap{
  height: fit-content;
  width: fit-content;
  padding: 100px 80px;
  max-width: 780px;
  max-height: 80%;
}

.c-header-ticket-conf-text{
  overflow: auto;
  white-space: break-spaces;

  @include custom-scrollbar;
}

</style>